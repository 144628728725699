/**  =====================
      Authentication css start
==========================  **/

@font-face {
    font-family: 'CachetStdBook';
    //font-style: normal;
    //font-weight: 400;
    src: local('CachetStdBook'), url('../../../../assets/font/QuanSlim-ExtraLight.ttf') format('opentype'); 
    font-weight: normal;
      font-style: normal;
  }

  $medium: "only screen and (max-width : 1300px)";
  $semismall: "only screen and (max-width : 990px)";
  $small: "only screen and (max-width : 925px)";


.auth-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    min-width: 100%;
    min-height: 100vh;
    background: #3F3D56;
    padding-left: 5%;
    
    @media #{$small} {
        justify-content: center;
        padding-left: 0px;
     }

    @media (min-width: 1200px) {
        .container {
            max-width: 1140px;
        }
    }
    @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
        margin: 0 auto;

        > .row {
            min-height: 100vh;

            > .aut-bg-img {
                min-height: 100vh;
            }
        }
    }

    .saprator {
        position: relative;
        margin: 8px 0;

        span {
            background: #fff;
            position: relative;
            padding: 0 10px;
            z-index: 5;
            font-size: 20px;

            
        }

        &:after {
            content: "";
            position: absolute;
            top: 15px;
            left: 0;
            width: 100%;
            height: 1px;
            background: $theme-border;
            z-index: 1;
        }
    }

    a,
    p > a {
        color: $theme-heading-color;
        font-weight: 600;
    }

    .input-group {
        background: transparent;
    }

    .card {
        margin-bottom: 0;
        padding: 8px;
        box-shadow: 0 2px 18px -2px rgba(0, 0, 0, 0.5);

        .card-body {
            padding: 20px 25px 20px 40px;
        }
        @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: block;
        }
    }

    > div {
        z-index: 5;
    }

    .auth-content {
        right: 70px;
        position: absolute;        
        padding: 15px;
        z-index: 5;
        border-radius: 20px; 

        @media #{$small} {
            display: fixed;
            right: auto;
         }


        &:not(.container) {
            width: 400px;

            .card-body {
                padding: 40px 35px;
            }
        }
        @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
            margin: 0 auto;
        }
    }

    .auth-side-img {
        padding-right: 400px;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        justify-content: center;
        @media only screen and (max-width: 991px) {
            display: none;
        }
    }

    .auth-side-form {
        position: relative;
        min-height: 100%;
        background: #fff;
        display: inline-flex;
        align-items: center;
        left: auto;
        float: right;
        box-shadow: -2px 0 7px -2px rgba(0, 0, 0, 0.5);

        > * {
            position: relative;
            z-index: 5;
        }
        @media only screen and (max-width: 991px) {
            width: 100%;
            justify-content: center;

            .auth-content:not(.container) {
                max-width: 350px;
            }
        }
    }

    &.aut-bg-img {
        .auth-side-form {
            @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
                height: 100vh;
            }
        }
    }
    @media only screen and (max-width: 767px) {
        .card {
            .card-body {
                padding: 30px 15px;
            }
        }
    }

    &.offline {
        background-image: none;

        h1 {
            font-size: 87px;
            font-weight: 700;
        }

        &:before {
            display: none;
        }
    }

    .card-body .carousel-indicators {
        margin: 15px 0 10px;
        bottom: 0;

        li {
            width: 50px;
            background-color: transparentize($primary-color, 0.6);
            border-radius: 5px;
            height: 4px;

            &.active {
                background-color: $primary-color;
            }
        }
    }

    .img-logo-overlay {
        position: absolute;
        top: 40px;
        left: 50px;
    }
}
/* image varient start */
.aut-bg-img {
    background-image: url("../../../images/auth/img-auth-big.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;

    
}

.auth-content {
    padding: 70px 40px;

    
}

.yellow-div{
    
    background: #DABF4A;
    min-width: 75vw;
    min-height: 93vh;
    border-radius: 20px; 

    @media #{$small} {
        background: #dac04a00;
     }


    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 20%;
    
    
    @media #{$medium} {
        align-items: flex-start;
        padding-left: 10%;
     }

     @media #{$semismall} {
        align-items: flex-start;
        padding-left: 4%;
     }

     @media #{$small} {
        align-items: center;
        padding-left: 0px;
     }
    
    h1{
        color:#fff;
        font-family: "CachetStdBook";
        display: inline;
        font-size: 50px;

      

        @media #{$small} {
           display: none;
        }

    }
    
    > img{
        width: 350px;
        margin: 10px;


        @media #{$small} {
            display: none;
         }
    }

    svg {
        color : "red";
    }


    
    }

    .figures_box{
        position: fixed;
        display: inline;
        min-width: 1100px;
        min-height: 630px;
        height: 100vh;
        width: 100vw;
        left: 0px;

        @media #{$small} {
            display: none;
         }
    }



/* image varient End */
/**====== Authentication css end ======**/
